import Styles from "./SuggestPrograms.module.scss";
import React, { useState, useEffect } from "react";
import ButtonWithLink from "../buttons/ButtonWithLink";
import { appLevelConstants } from "../../constants/AppLevelConstants";
import GetInTouch from "../common_components/forms/GetInTouch";
import AOS from "aos";

export default function SuggestPrograms({
  handleSucess,
  handleError,
  buttonId,
}) {
  useEffect(() => {
    AOS.init();
  }, []);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSucessToasterGIT = (messageGIT) => {
    handleSucess(messageGIT);
  };
  const handleErrorToasterGIT = (messageGIT) => {
    handleError(messageGIT);
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  return (
    <>
      {dialogOpen && (
        <GetInTouch
          setDialogOpen={setDialogOpen}
          open={dialogOpen}
          programName="NA"
          sucessToasterGIT={handleSucessToasterGIT}
          errorToasterGIT={handleErrorToasterGIT}
          isDemo={true}
        />
      )}
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        className={Styles["suggest-programs"]}
      >
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["suggest-program"]}
        >
          <div className={Styles["suggest-program-container"]}>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              className={Styles["suggest-program-container-margin"]}
            >
              <span className={Styles["suggest-title"]}>
                {appLevelConstants.SuggestPrograms.mainTitle.title}
                <br className="mobile"></br>{" "}
                {appLevelConstants.SuggestPrograms.mainTitle.title1}
              </span>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              className={Styles["suggest-program-container-button"]}
            >
              <div
                id="suggest-program"
                className={Styles["get-in-touch"]}
                onClick={handleClickOpen}
              >
                <ButtonWithLink
                  name="Speak to our Counsellor"
                  className={"button-red"}
                  id={buttonId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
