import { NavLink } from "react-router-dom";
import Styles from "./Header.module.scss";
import { HiMenu } from "react-icons/hi";
import { CgClose } from "react-icons/cg";
import { appLevelConstants } from "../../../constants/AppLevelConstants";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getMenuListData } from "../../../service/HeaderService";
import { useDispatch } from "react-redux";
import { addApiData } from "../../../redux/appDataSlice";
import { urls } from "../../../utils/urls";

function Links({ setTpenDropdown }) {
  const wi = window.innerWidth;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [isSubDropdownOpen, setIsSubDropdownOpen] = useState(false);
  const [subDropdownData, setSubDropdownData] = useState([]);
  const dispatch = useDispatch();

  const handleScreenWidthChange = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleScreenWidthChange);
    return () => {
      window.removeEventListener("resize", handleScreenWidthChange);
    };
  }, []);

  useEffect(() => {
    getPrograms();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubDropdownToggle = () => {
    // if(subDropdownData.length !== 0){
    setIsSubDropdownOpen(!isSubDropdownOpen);
    // }
  };

  const handleSubDropdownClose = () => {
    setTimeout(() => {
      setIsSubDropdownOpen(false);
    }, 1000);
  };

  const getPrograms = async () => {
    try {
      const response = await getMenuListData();
      dispatch(addApiData(response.data.data));
      let headers = response.data?.data?.individual_program?.map((item) => ({
        id: item.id,
        title: item.title.split("_").join(" "),
        to: "/individualPrograms",
      }));
      setSubDropdownData(headers);
    } catch (error) {
      console.log(error, "error++");
    }
  };

  const container =
    currentPath === "/"
      ? {
          hidden: { opacity: 0 },
          visible: (i = 1) => ({
            opacity: 1,
            transition: { staggerChildren: 0.12, delayChildren: 0.04 * i },
          }),
        }
      : {
          visible: {
            opacity: 1,
          },
        };
  const child =
    currentPath === "/"
      ? {
          visible: {
            opacity: 1,
            y: 0,
            transition: {
              type: "spring",
              damping: 12,
              stiffiness: 100,
            },
          },
          hidden: {
            opacity: 0,
            y: 0,
            transition: {
              type: "spring",
              damping: 12,
              stiffiness: 100,
            },
          },
        }
      : {
          visible: {
            opacity: 1,
            y: 0,
            transition: {
              type: "spring",
              damping: 12,
              stiffiness: 100,
            },
          },
          hidden: {
            opacity: 0,
            y: 0,
            transition: {
              type: "spring",
              damping: 12,
              stiffiness: 100,
            },
          },
        };

  const [isSubOpen, setSubOpen] = useState(false);
  const submenu = () => {
    setSubOpen(!isSubOpen);
  };

  const submenuclose = () => {
    setSubOpen(false);
  };

  useEffect(() => {
    appLevelConstants.Header.headers.map((val) => {
      if (val.to === currentPath) {
        setActiveItem(val);
      } else if (appLevelConstants.Programs.includes(currentPath)) {
        setActiveItem(currentPath);
      }
    });
  }, [currentPath]);

  // const [activeItem, setActiveItem] = useState({ name: "Home" });
  const [activeItem, setActiveItem] = useState({});
  const handleItemClick = (item) => {
    window.scrollTo(0, 0);
    setActiveItem(item);
    if (wi <= "768") {
      setTpenDropdown(false);
    }
    if (item.target === "_blank") {
      window.open("https://workshops.iamironlady.com/masterclass_app", "_blank");
    }
  };

  return (
    <motion.ul
      variants={container}
      initial="hidden"
      animate="visible"
      className={Styles["menulist-ul"]}
    >
      {appLevelConstants.Header.headers.map((item, i) =>
        item.name === "Programs" ? (
          <motion.li
            variants={child}
            className={Styles["menulist-li"]}
            onMouseEnter={handleSubDropdownToggle}
            onMouseLeave={handleSubDropdownClose}
            key={i}
          >
            <NavLink
              onClick={() => handleItemClick(item)}
              className={`${
                activeItem.name !== item.name
                  ? Styles["hover-underline-animation"]
                  : ""
              } ${
                activeItem.name === item.name ? Styles["menu-list-active"] : ""
              } ${Styles["menu-list-a"]}`}
              to={item.to}
              key={i}
            >
              {item.name}
            </NavLink>

            {(isSubDropdownOpen || isSubOpen) && (
              <ul
                className={Styles["program-submenu"]}
                onMouseEnter={submenu}
                onMouseLeave={submenuclose}
              >
                {subDropdownData?.map((item) => (
                  <li key={item.id}>
                    <Link
                      onClick={() => handleItemClick(item)}
                      to={item.to + "/" + item.title.split(" ").join("_")}
                      className={`${Styles["submenu-items"]} ${Styles["submenu-items-a"]}`}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </motion.li>
        ) : (
          // this is commented for phase 1

          // : i === appLevelConstants.Header.headers.length - 1 ? (
          //   <motion.li variants={child} className={Styles["menulist-li"]} key={i}>
          //     <a
          //       href={item.to}
          //       className={`${
          //         activeItem.name !== item.name ? Styles["hover-underline-animation"] : ""
          //       } ${
          //         activeItem.name === item.name ? Styles["menu-list-active"] : ""
          //       } ${Styles["menu-list-a"]}`}
          //       // target="_blank"        ----------------- for phase 1 (because login will be display in phase 2)
          //       rel="noopener noreferrer"
          //     >
          //       {item.name}
          //     </a>
          //   </motion.li>
          // )
          // Render other items as NavLink components
          <motion.li
            variants={child}
            className={Styles["menulist-li"]}
            key={i}
            onMouseEnter={() => {
              if (screenWidth <= 768) {
                navigate(item.to);
                setTpenDropdown(false);
              }
            }}
          >
            <NavLink
              onClick={() => handleItemClick(item)}
              className={`${
                activeItem.name !== item.name
                  ? Styles["hover-underline-animation"]
                  : ""
              } ${
                activeItem.name === item.name ? Styles["menu-list-active"] : ""
              } ${Styles["menu-list-a"]}`}
              to={item.to}
              key={i}
            >
              {item.name}
            </NavLink>
          </motion.li>
        )
      )}
    </motion.ul>
  );
}

export default function Header() {
  const screenWidth = window.innerWidth;
  let [openDropdown, setTpenDropdown] = useState(false);
  const [showLogo, setShowLogo] = useState("");
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    const currentUrl = location.pathname;
    setShowLogo(currentUrl);
  }, [location]);

  const [showDiv, setShowDiv] = useState(false);
  useEffect(() => {
    setShowDiv(location.pathname === "/" || location.pathname === "/facebook" || location.pathname === "/google" || location.pathname === "/linkedin" || location.pathname === "/instagram" ? true : false)
  },[])

  const [showDivColor, setShowDivColor] = useState(false);

  useEffect(() => {
    if (screenWidth >= 768 && (location.pathname === "/" || location.pathname === "/facebook" || location.pathname === "/google" || location.pathname === "/linkedin" || location.pathname === "/instagram")) {
      setTimeout(() => {
        setShowDivColor(true);
      }, 3000);
    } else {
      setShowDivColor(true);
    }
  }, [screenWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  const setDropDown = (data) => {
    setTpenDropdown(data);
  };

  return (
    <nav className={Styles[showDiv ? "animation" : "animationn"]}>
      {showDivColor && (
        <div className={Styles["main-nav"]}>
          <div className={Styles["menu-list-mobile"]}>
            {!openDropdown ? (
              <HiMenu
                className={Styles["three-lines"]}
                onClick={() => setTpenDropdown(!openDropdown)}
              ></HiMenu>
            ) : (
              <CgClose
                className={Styles["three-lines"]}
                onClick={() => setTpenDropdown(!openDropdown)}
              ></CgClose>
            )}
          </div>

          <div className={Styles["logo"]}>
            <span>
              <img
                className={Styles["logo-h"]}
                alt="logo"
                src={urls.blackLogo}
                onClick={() => navigate("/")}
              ></img>
            </span>
          </div>

          <div className={Styles["menu-list"]}>
            <Links />
          </div>
          <div className={Styles["common-logo-div"]}>
            <div
              className={
                Styles[
                  appLevelConstants.headerLogo.includes(showLogo)
                    ? ""
                    : "common-logo-div-hidden"
                ]
              }
            >
              <Link to="/">
                {" "}
                <img
                  className={Styles["common-logo"]}
                  src={urls.blackLogo}
                  alt=""
                />{" "}
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className={Styles["menu-list-mobile"]}>
        {openDropdown ? <Links setTpenDropdown={setDropDown} /> : null}
      </div>
    </nav>
  );
}
