import Styles from "./ContactUs.module.scss";

export default function ContactUs() {
    return(
        <div className={Styles["main-parent-div"]}>

 <div>
          <div className={Styles["content1-div"]}>
          <span className={Styles["con"]}>
        <tr>
        <b>Contact Iron Lady</b>
        </tr>
        </span>
        <tr>
        Thank you for your interest in Iron Lady. We value your feedback and inquiries. Feel free to reach out to us using the contact information below:
        </tr>
       <br></br>
       <br></br>
        <tr>
        <b>
        Phone: </b> +91 98459 62474
        </tr>
        <br></br>
               <br></br>
        <tr>
        <b>Email:</b> admin@iamironlady.com
        </tr>
        <br></br>
        <br></br>
        <tr>
        <b>Office Address:</b> Iron Lady Office NO 643, 11TH MAIN, HAL 2ND STAGE, INDIRANAGAR, NEXT TO INDIRANAGAR CLUB, Bengaluru (Bangalore) Urban, Karnataka, 560038
        </tr>
        <br></br>
        <br></br>
        <tr>
        We are here to assist you and look forward to hearing from you soon.
        </tr>

        </div>
        </div>
        </div>
    )
}