import Styles from "./PriceStructure.module.scss";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { appLevelConstants } from "../../constants/AppLevelConstants";
import { Link } from "react-router-dom";
import ButtonWithLink from "../buttons/ButtonWithLink";
import { individualProgram, registerProgram } from "../../service/formService";
import { decryptObject } from "../../helper-fuction/helper";
import GetInTouch from "../common_components/forms/GetInTouch";
// import { useNavigate } from "react-router-dom";

export default function PriceStructure({
  handleSucess,
  handleError,
  filteredData,
}) {
  // let navigate = useNavigate();
  const { programName } = useParams();
  const [formLink, setFormLink] = useState("");
  const [getInTouchOpen, setGetInTouchOpen] = useState(false);
  const ID =
    filteredData?.programFee?.ProgramFee &&
    filteredData?.programFee?.ProgramFee[1]?.id;

  const newProgramName = programName.split("_").join(" ");

  const openMasterClass = () => {
    window.open("https://workshops.iamironlady.com/masterclass_app", "_blank")
  };

  const getLepRegistrationForm = async () => {
    try {
      const response = await individualProgram();
      const encryptedLepProgramRegistrationUrl = response.data.data;
      const decryptLepProgramRegistrationUrl = decryptObject(
        encryptedLepProgramRegistrationUrl,
        appLevelConstants.REACT_APP_SECRET_KEY
      );
      setFormLink(
        decryptLepProgramRegistrationUrl.lepProgramMasterclassPageUrl
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLepRegistrationForm();
  }, []);

  const handleSucessToasterGIT = (messageGIT) => {
    handleSucess(messageGIT);
  };
  const handleErrorToasterGIT = (messageGIT) => {
    handleError(messageGIT);
  };

  const register = async (item) => {
    let program = item?.title;
    let payload = {
      programName: program?.replace("%20", /\s/g),
      price: filteredData?.registrationFee,
      gstPricePercentage: 0,
      formType: "Program Registration",
      fullPayment: filteredData?.fullPayment,
    };
    try {
      const response = await registerProgram(payload);
      let encryptedMessage = response.data.data;
      const extracted = encryptedMessage.split("/");
      window.open("/programRegistration/" + extracted[4], "_blank");
      // navigate("/programRegistration/" + extracted[4]);
    } catch (error) {}
  };

  return (
    <>
      {getInTouchOpen && (
        <GetInTouch
          isDemo={true}
          isRegister={true}
          isWorkProfile={true}
          isSeeYourSelf={true}
          isExp={true}
          setDialogOpen={setGetInTouchOpen}
          open={getInTouchOpen}
          programName={newProgramName}
          sucessToasterGIT={handleSucessToasterGIT}
          errorToasterGIT={handleErrorToasterGIT}
          // callback={handleCallback}
        />
      )}

      <div className={Styles["parent-main-div"]}>
        <span className={Styles["main-title"]}>
          {filteredData?.programFee.fee}
        </span>

        <div className={Styles["collapse-div"]}>
          {filteredData?.programFee.ProgramFee &&
            filteredData?.programFee.ProgramFee.map((item, index) => {
              return (
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  className={
                    Styles[
                      index !== filteredData?.programFee.ProgramFee &&
                      filteredData?.programFee.ProgramFee.length - 1
                        ? "session-div"
                        : "session-div-last"
                    ]
                  }
                  key={index}
                >
                  <div className={Styles["session-arrow-div"]}>
                    <div
                      className={
                        Styles[item.id !== ID ? "session" : "session-after"]
                      }
                      // onClick={() => showDiv(item.id)}
                    >
                      {item.title}
                      <span className={Styles["subTitle"]}>
                        {item.subtitle}
                      </span>
                    </div>
                  </div>

                  {index === 0 && item.title === "Master Class" && (
                    <>
                      <div className={Styles["master-class-div"]}>
                        <ul className={Styles["details"]}>
                          {item.value.map((val) => (
                            <li key={val.id}>{val.value1}</li>
                          ))}
                        </ul>
                        <div
                          className={Styles["know-more-details"]}
                          onClick={openMasterClass}
                        >
                          <ButtonWithLink
                            id="lepKnowMoreDetails"
                            name="Know More Details"
                            className={"button-width-red"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {index === 1 &&
                    item.title === "Leadership Essentials Program" && (
                      <>
                        <div className={Styles["session-details-div"]}>
                          <ul className={Styles["details"]}>
                            {item.value.map((val) => (
                              <li key={val.id}>{val.value1}</li>
                            ))}
                          </ul>
                          <div
                            className={Styles["know-more-details"]}
                            onClick={() => register(item)}
                          >
                            <ButtonWithLink
                              id="lepApplyFoProgram"
                              name="Apply For Program"
                              className={"button-width-red"}
                            />
                          </div>
                        </div>

                        <div className={Styles["policy-div"]}>
                          <Link
                            to={`/individualPrograms/${programName}/policy`}
                            className={Styles["policy"]}
                            id="lep_cancellation_policy"
                          >
                            {filteredData?.programFee.cancellation}
                          </Link>
                        </div>
                      </>
                  )}

                  {index === 0 &&
                    item.title === "100 Board Members Program" && (
                      <>
                        <div className={Styles["session-details-div"]}>
                          <ul className={Styles["details"]}>
                            {item.value.map((val) => (
                              <li key={val.id}>{val.value1}</li>
                            ))}
                          </ul>
                          <div
                            className={Styles["know-more-details"]}
                            onClick={() => setGetInTouchOpen(true)}
                          >
                            <ButtonWithLink
                              id="lepKnowMoreDetails"
                              name="Know More Details"
                              className={"button-width-red"}
                            />
                          </div>
                        </div>

                        <div className={Styles["policy-div"]}>
                          <Link
                            to={`/individualPrograms/${programName}/policy`}
                            className={Styles["policy"]}
                            id="lep_cancellation_policy"
                          >
                            {filteredData?.programFee.cancellation}
                          </Link>
                        </div>
                      </>
                  )}

                  {index === 0 &&
                    item.title === "Master of Business Warfare Program" && (
                      <>
                        <div className={Styles["session-details-div"]}>
                          <ul className={Styles["details"]}>
                            {item.value.map((val) => (
                              <li key={val.id}>{val.value1}</li>
                            ))}
                          </ul>
                          <div
                            className={Styles["know-more-details"]}
                            onClick={() => setGetInTouchOpen(true)}
                          >
                            <ButtonWithLink
                              id="lepKnowMoreDetails"
                              name="Know More Details"
                              className={"button-width-red"}
                            />
                          </div>
                        </div>

                        <div className={Styles["policy-div"]}>
                          <Link
                            to={`/individualPrograms/${programName}/policy`}
                            className={Styles["policy"]}
                            id="lep_cancellation_policy"
                          >
                            {filteredData?.programFee.cancellation}
                          </Link>
                        </div>
                      </>
                  )}

                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
