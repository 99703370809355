import { useNavigate } from "react-router-dom";
import Style from "../buttons/ButtonWithLink.module.scss";
export default function ButtonWithLink({className,linkTo,name,callback, id}) {
  let navigate = useNavigate();
  const handleClick = () => {
    callback();
  };
  return (
    <button id={id} className={Style[className]} onClick={() => linkTo === 'action' ? handleClick() : navigate(linkTo)}>
      {name}
    </button>
  );
}
