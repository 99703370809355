import React from "react";
import { useParams } from "react-router-dom";
import Styles from "./ProgramMain.module.scss";
import ButtonWithLink from "../buttons/ButtonWithLink";
import { useState, useEffect } from "react";
import AOS from "aos";
import { registerProgram } from "../../service/formService";
// import { useNavigate } from "react-router-dom";
import GetInTouch from "../common_components/forms/GetInTouch";

export default function ProgramMain({ bookMySpot, handleSucess, handleError, buttonId, filteredData }) {
  // let navigate = useNavigate();
  const { programName } = useParams();
  const { letsConnect } = useParams();
  const [getInTouchOpen, setGetInTouchOpen] = useState(letsConnect ? letsConnect : false);

  useEffect(() => {
    AOS.init();
  }, []);

  const newProgramName = programName.split("_").join(" ");

  const handleSucessToasterGIT = (messageGIT) => {
    handleSucess(messageGIT);
  };
  const handleErrorToasterGIT = (messageGIT) => {
    handleError(messageGIT);
  };

  const register = async (item) => {
    let program = item;
    let payload = {
      programName: program?.replace(/_/g, ' '),
      price: filteredData?.registrationFee,
      gstPricePercentage: 0,
      formType: 'Program Registration',
      fullPayment: filteredData?.fullPayment
    };
    try {
      const response = await registerProgram(payload);
      let encryptedMessage = response.data.data;
      const extracted = encryptedMessage.split("/");
      window.open("/programRegistration/" + extracted[4], "_blank")
      // navigate("/programRegistration/" + extracted[4]);
    } catch (error) { }
  };

  return (
    <>
      {getInTouchOpen && (
        <GetInTouch
          isDemo={false}
          isRegister={true}
          isWorkProfile={true}
          isSeeYourSelf={true}
          isExp={true}
          setDialogOpen={setGetInTouchOpen}
          open={getInTouchOpen}
          programName={newProgramName}
          sucessToasterGIT={handleSucessToasterGIT}
          errorToasterGIT={handleErrorToasterGIT}
        />
      )}

      <div className={Styles["program"]}>
        <div className={Styles["program_main"]}>
          <span
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            className={Styles["program_name"]}
          >
            {filteredData?.title?.replace(/_/g, ' ')}
          </span>
          <div className={Styles["program_video"]}>
            <div>
              <video
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                className={Styles["programImage"]}
                controls
                poster={filteredData?.programMain?.videoThumbneil}
              >
                <source
                  src={filteredData?.programMain?.videoLink}
                  type="video/mp4"
                />
              </video>
            </div>
            <div className={Styles["program_content"]}>
              <div className={Styles["program-question"]}>
                <span
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  className={Styles["content"]}
                  style={{marginBottom: '16px'}}
                >
                  {
                    window.location.pathname === "/individualPrograms/100_Board_Members_Program" ? (filteredData?.programMain?.question1?.split(" ").map((word, wordIndex) => {
                      if (wordIndex === 4 || wordIndex === 5 || wordIndex === 6 || wordIndex === 8 || wordIndex === 13 || wordIndex === 14) {
                        return (
                          <span style={{ fontWeight: "bold", color: '#F52929' }} key={wordIndex}>
                            {" "}
                            {word}
                          </span>
                        );
                      } else {
                        return <span key={wordIndex}> {word}</span>;
                      }
                    })) : (filteredData?.programMain?.question1)
                  }

                </span>
                <span
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  className={Styles["content"]}
                >
                  {" "}
                  {filteredData?.programMain?.question2}
                </span>
                <span
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  className={Styles["content"]}
                >
                  {" "}
                  {filteredData?.programMain?.question3}
                </span>
              </div>
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                className={Styles["content"]}
              >
              {
                window.location.pathname === "/individualPrograms/100_Board_Members_Program" ? (
                  filteredData?.programMain?.content2?.split(" ").map((word, wordIndex) => {
                    if (wordIndex === 3 || wordIndex === 4 || wordIndex === 5 || wordIndex === 6 || wordIndex === 16 || wordIndex === 17 || wordIndex === 18) {
                      return (
                        <span style={{ fontWeight: "bold", color: '#F52929' }} key={wordIndex}>
                          {" "}
                          {word}
                        </span>
                      );
                    } else {
                      return <span key={wordIndex}> {word}</span>;
                    }
                  })
                ) : (
                  window.location.pathname === "/individualPrograms/Leadership_Essentials_Program" ? (
                    filteredData?.programMain?.content2?.split(" ").map((word, wordIndex) => {
                      if (wordIndex === 6 || wordIndex === 7) {
                        return (
                          <span style={{ fontWeight: "bold", color: '#F52929' }} key={wordIndex}>
                            {" "}
                            {word}
                          </span>
                        );
                      } else {
                        return <span key={wordIndex}> {word}</span>;
                      }
                    })
                  ) : (
                    <span> {filteredData?.programMain?.content2}</span>
                  )
                )
              }
              
              </p>

              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                className={Styles["content-last"]}
              >
                {" "}
                {filteredData?.programMain?.content3}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        {window.location.pathname ===
          "/individualPrograms/Leadership_Essentials_Program" ? (
          <div
            className={
              Styles[bookMySpot === false ? "book_spot" : "bottom-book_spot"]
            }
          >
            <div onClick={() => register(programName)}>
              <ButtonWithLink
                id={buttonId}
                name="Apply for the Program"
                className={"button-width-red"}
              />
            </div>
          </div>
        ) : (
          <div
            className={
              Styles[bookMySpot === false ? "book_spot" : "bottom-book_spot"]
            }
          >
            <div onClick={() => setGetInTouchOpen(true)}>
              <ButtonWithLink
                id={buttonId}
                name="Apply for the Program"
                className={"button-width-red"}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}


// {" "}
// {filteredData?.programMain?.question1}