import Styles from "../breakthrough-programs/BreakthroughPrograms.module.scss";
import Button from "../buttons/ButtonWithLink";
import AOS from "aos";
import { useEffect, useState } from "react";
import GetInTouch from "../common_components/forms/GetInTouch";
import { appLevelConstants } from "../../constants/AppLevelConstants";

export default function BreakthroughPrograms({handleSucess, handleError}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleSucessToasterGIT = (messageGIT) => {
    handleSucess(messageGIT)
  };
  const handleErrorToasterGIT = (messageGIT) => {
    handleError(messageGIT)
  };
  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
    {dialogOpen && (
      <GetInTouch setDialogOpen={setDialogOpen} open={dialogOpen} programName = 'NA' sucessToasterGIT={handleSucessToasterGIT} errorToasterGIT={handleErrorToasterGIT} isDemo={true} />
    )}
    <div className={Styles["breakthrough-main-container"]}>
      <div className={Styles["breakthrough-inner-container"]}>
        <span
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["breakthrough-title"]}
        >
          {appLevelConstants.breakThrough}
        </span>
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className={Styles["get-started"]}
          onClick={handleClickOpen}
        >
          <Button name="Get Started"  className={'button'}></Button>
        </div>
      </div>
    </div></>
  );
}
