// import React, { useEffect, useMemo, useState } from "react";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import parse, { domToReact } from "html-react-parser";
// import Styles from "./blog.module.scss";
// import {
//   getAllPostsData,
//   getFeedDetailsByName,
// } from "../../service/communityService.js";
// import { ThreeCircles } from "react-loader-spinner";
// import GetInTouch from "../common_components/forms/GetInTouch.js";
// import { toast } from "react-toastify";
// import RelatedBlogs from "../related-blogs/RelatedBlogs.js";

// const Blog = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { title, category, subcategory } = useParams();
//   const [feedContent, setFeedContent] = useState("");
//   const [elements, setElements] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [blogs, setBlogs] = useState([]);
//   const [relatedBlogs, setRelatedBlogs] = useState([]);

//   const id = location.state ? location.state.id : null;
//   const slug = location.state ? location.state.slug : null;

//   const keywords = useMemo(() => [
//     { keyword: "Rajesh", link: "http://localhost:3000/aboutUs#Rajesh" },
//     { keyword: "Suvarna", link: "http://localhost:3000/aboutUs#Suvarna" },
//     { keyword: "Leadership Essential Program", link: "http://localhost:3000/individualPrograms/Leadership_Essentials_Program" },
//     { keyword: "Masterclass", link: "https://workshops.iamironlady.com/masterclass_app" },
//   ], []);   

//   const handleSucess = (messageGIT) => {
//     toast.success(messageGIT, {
//       position: toast.POSITION.TOP_RIGHT,
//       limit: 1,
//       autoClose: 5000,
//       hideProgressBar: false,
//       newestOnTop: false,
//       rtl: false,
//       containerId: "getInTouch",
//     });
//   };
//   const handleError = (messageGIT) => {
//     toast.error(messageGIT, {
//       position: toast.POSITION.TOP_RIGHT,
//       limit: 1,
//       autoClose: 5000,
//       hideProgressBar: false,
//       newestOnTop: false,
//       rtl: false,
//       containerId: "getInTouch",
//     });
//   };

//   const handleSucessToasterGIT = (messageGIT) => {
//     handleSucess(messageGIT);
//   };
//   const handleErrorToasterGIT = (messageGIT) => {
//     handleError(messageGIT);
//   };

//   const handleClickOpen = () => {
//     setDialogOpen(true);
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         let data;

//         if (id) {
//           data = await getFeedDetailsByName(slug);
//         } else {
//           data = await getFeedDetailsByName(title);
//         }
//         setFeedContent(data.content.rendered);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching feed details:", error);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [id, slug, title]);

//   useEffect(() => {
//     if (feedContent) {
//       const parseOptions = {
//         replace: (domNode) => {
//           if (domNode.name === "h3" || domNode.name === "h5" || domNode.name === "p" || domNode.name === "b") {
//             const content = domToReact(domNode.children);
//             const modifiedContent = replaceKeywordsWithLinks(content, keywords);
//             return React.createElement(domNode.name, { key: domNode.attribs.key }, modifiedContent);
//           }
//         },
//       };

//       const parsedContent = parse(feedContent, parseOptions);
//       setElements(parsedContent);
//     }
//   }, [feedContent, keywords]);

//   const replaceKeywordsWithLinks = (content, keywords) => {
//     if (typeof content === 'string') {
//       let modifiedText = content;
//       keywords.forEach(({ keyword, link }) => {
//         const regex = new RegExp(`\\b${keyword}\\b`, 'g');
//         modifiedText = modifiedText.replace(regex, `<a href="${link}" target="_blank" class="${Styles['keyword-link']}" rel="noopener noreferrer">${keyword}</a>`);
//       });
//       return parse(modifiedText);
//     }

//     if (Array.isArray(content)) {
//       return content.map((node) => {
//         if (typeof node === 'string') {
//           let modifiedText = node;
//           keywords.forEach(({ keyword, link }) => {
//             const regex = new RegExp(`\\b${keyword}\\b`, 'g');
//             modifiedText = modifiedText.replace(regex, `<a href="${link}" target="_blank" rel="noopener noreferrer">${keyword}</a>`);
//           });
//           return parse(modifiedText);
//         } else if (node && node.props && node.props.children) {
//           const modifiedChildren = replaceKeywordsWithLinks(node.props.children, keywords);
//           return React.cloneElement(node, { ...node.props, children: modifiedChildren });
//         }
//         return node;
//       });
//     }

//     return content;
//   };

//   useEffect(() => {
//     const fetchBlogs = async () => {
//       setLoading(true);
//       try {
//         const response = await getAllPostsData();
//         const data = await JSON.parse(response);
//         setBlogs(data.blog);

//         const related = data.blog.filter(
//           (blog) =>
//             blog.PostCategory === category &&
//             blog.PostSubCategory === subcategory
//         );
//         setRelatedBlogs(related);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching blogs:", error);
//         setLoading(false);
//       }
//     };

//     if (category && subcategory) {
//       fetchBlogs();
//     }
//   }, [category, subcategory]);

//   const handleBlogClick = (blog) => {
//     setLoading(true);
//     navigate(`/blogs/${blog.PostCategory}/${blog.PostSubCategory}/${blog.PostSlug}`, { state: { id: blog.id, slug: blog.PostSlug } });
//   };

//   return (
//     <>
//       {dialogOpen && (
//         <GetInTouch
//           setDialogOpen={setDialogOpen}
//           open={dialogOpen}
//           programName="NA"
//           sucessToasterGIT={handleSucessToasterGIT}
//           errorToasterGIT={handleErrorToasterGIT}
//           isDemo={true}
//         />
//       )}

//       <div className={Styles["main-div"]}>
//         {loading ? (
//           <ThreeCircles
//             height="70"
//             width="70"
//             color="#F52929"
//             wrapperStyle={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               width: "100%",
//               height: "20vh",
//             }}
//             wrapperClass=""
//             visible={true}
//             ariaLabel="three-circles-rotating"
//           />
//         ) : (
//           <>
//             {elements.map((element, index) => (
//               <div key={index}>{element}</div>
//             ))}

//             {relatedBlogs.length > 0 && (
//               <RelatedBlogs
//                 relatedBlogs={relatedBlogs.filter(blog => blog.id !== id)}
//                 handleBlogClick={handleBlogClick}
//               />
//             )}


//             <div onClick={() => handleClickOpen()}>
//               <button className={Styles["cta-button"]}>Know More</button>
//             </div>
//           </>
//         )}
//       </div>
//     </>
//   );
// };

// export default Blog;


import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import parse, { domToReact } from "html-react-parser";
import Styles from "./blog.module.scss";
import {
  getAllBlogKeywordDetails,
  getAllPostsData,
  getFeedDetailsByName,
} from "../../service/communityService.js";
import { ThreeCircles } from "react-loader-spinner";
import GetInTouch from "../common_components/forms/GetInTouch.js";
import { toast } from "react-toastify";
import RelatedBlogs from "../related-blogs/RelatedBlogs.js";

const Blog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { title, category, subcategory } = useParams();
  const [feedContent, setFeedContent] = useState("");
  const [elements, setElements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [keywords, setKeywords] = useState([]);

  const id = location.state ? location.state.id : null;
  const slug = location.state ? location.state.slug : null;

  const handleScrollToItem = (id) => {
    navigate('/aboutUs', { state: { scrollTo: 'programCurtators', itemId: id } });
  };  

  const handleSucess = (messageGIT) => {
    toast.success(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "getInTouch",
    });
  };
  const handleError = (messageGIT) => {
    toast.error(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "getInTouch",
    });
  };

  const handleSucessToasterGIT = (messageGIT) => {
    handleSucess(messageGIT);
  };
  const handleErrorToasterGIT = (messageGIT) => {
    handleError(messageGIT);
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const response = await getAllBlogKeywordDetails();
        
        if (response.statusCode === 200) {
          const formattedKeywords = response.data.map(item => ({
            keyword: item.name,
            link: item.description.startsWith('http') ? item.description : null,
            id: !item.description.startsWith('http') ? parseInt(item.description) : null
          }));
          setKeywords(formattedKeywords);
        }
      } catch (error) {
        console.error("Error fetching keywords:", error);
      }
    };

    fetchKeywords();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data;

        if (id) {
          data = await getFeedDetailsByName(slug);
        } else {
          data = await getFeedDetailsByName(title);
        }
        setFeedContent(data.content.rendered);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching feed details:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, slug, title]);

  useEffect(() => {
    if (feedContent) {
      const parseOptions = {
        replace: (domNode) => {
          if (domNode.name === "h3" || domNode.name === "h5" || domNode.name === "p" || domNode.name === "b") {
            const content = domToReact(domNode.children, parseOptions); // Recursively parse children
            const modifiedContent = replaceKeywordsWithLinks(content, keywords);
            return React.createElement(domNode.name, { key: domNode.attribs.key }, modifiedContent);
          }
        },
      };
  
      const parsedContent = parse(feedContent, parseOptions);
      setElements(parsedContent);
    }
  }, [feedContent, keywords]);
  

  // const replaceKeywordsWithLinks = (content, keywords) => {
  //   if (typeof content === 'string') {
  //     let modifiedText = content;
  //     keywords.forEach(({ keyword, link }) => {
  //       const regex = new RegExp(`\\b${keyword}\\b`, 'g');
  //       modifiedText = modifiedText.replace(regex, `<a href="${link}" target="_blank" class="${Styles['keyword-link']}" rel="noopener noreferrer">${keyword}</a>`);
  //     });
  //     return parse(modifiedText);
  //   }

  //   if (Array.isArray(content)) {
  //     return content.map((node) => {
  //       if (typeof node === 'string') {
  //         let modifiedText = node;
  //         keywords.forEach(({ keyword, link }) => {
  //           const regex = new RegExp(`\\b${keyword}\\b`, 'g');
  //           modifiedText = modifiedText.replace(regex, `<a href="${link}" target="_blank" rel="noopener noreferrer">${keyword}</a>`);
  //         });
  //         return parse(modifiedText);
  //       } else if (node && node.props && node.props.children) {
  //         const modifiedChildren = replaceKeywordsWithLinks(node.props.children, keywords);
  //         return React.cloneElement(node, { ...node.props, children: modifiedChildren });
  //       }
  //       return node;
  //     });
  //   }

  //   console.log(content)

  //   return content;
  // };


  const replaceKeywordsWithLinks = (content, keywords) => {
    if (typeof content === 'string') {
      let modifiedText = content;
      keywords.forEach(({ keyword, link, id }) => {
        const regex = new RegExp(`\\b${keyword}\\b`, 'g');
        if (link) {
          modifiedText = modifiedText.replace(regex, `<a href="${link}" target="_blank" class="${Styles['keyword-link']}" rel="noopener noreferrer">${keyword}</a>`);
        } else if (id) {
          modifiedText = modifiedText.replace(regex, `<span class="${Styles['keyword-button']}" data-id="${id}">${keyword}</span>`);
        }
      });
      return parse(modifiedText);
    }

    if (Array.isArray(content)) {
      return content.map((node) => {
        if (typeof node === 'string') {
          let modifiedText = node;
          keywords.forEach(({ keyword, link, id }) => {
            const regex = new RegExp(`\\b${keyword}\\b`, 'g');
            if (link) {
              modifiedText = modifiedText.replace(regex, `<a href="${link}" target="_blank" rel="noopener noreferrer">${keyword}</a>`);
            } else if (id) {
              modifiedText = modifiedText.replace(regex, `<span class="${Styles['keyword-button']}" data-id="${id}">${keyword}</span>`);
            }
          });
          return parse(modifiedText);
        } else if (node && node.props && node.props.children) {
          const modifiedChildren = replaceKeywordsWithLinks(node.props.children, keywords);
          return React.cloneElement(node, { ...node.props, children: modifiedChildren });
        }
        return node;
      });
    }

    return content;
  };
  
  useEffect(() => {
    const handleButtonClick = (event) => {
      const target = event.target;
      if (target.classList.contains(Styles['keyword-button'])) {
        const id = target.getAttribute('data-id');
        handleScrollToItem(id);
      }
    };

    document.addEventListener('click', handleButtonClick);

    return () => {
      document.removeEventListener('click', handleButtonClick);
    };
  }, []);
  
  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      try {
        const response = await getAllPostsData();
        const data = await JSON.parse(response);
        setBlogs(data.blog);

        const related = data.blog.filter(
          (blog) =>
            blog.PostCategory === category &&
            blog.PostSubCategory === subcategory
        );
        setRelatedBlogs(related);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setLoading(false);
      }
    };

    if (category && subcategory) {
      fetchBlogs();
    }
  }, [category, subcategory]);

  const handleBlogClick = (blog) => {
    setLoading(true);
    navigate(`/blogs/${blog.PostCategory}/${blog.PostSubCategory}/${blog.PostSlug}`, { state: { id: blog.id, slug: blog.PostSlug } });
  };

  return (
    <>
      {dialogOpen && (
        <GetInTouch
          setDialogOpen={setDialogOpen}
          open={dialogOpen}
          programName="NA"
          sucessToasterGIT={handleSucessToasterGIT}
          errorToasterGIT={handleErrorToasterGIT}
          isDemo={true}
        />
      )}

      <div className={Styles["main-div"]}>
        {loading ? (
          <ThreeCircles
            height="70"
            width="70"
            color="#F52929"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "20vh",
            }}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
          />
        ) : (
          <>
            {elements.map((element, index) => (
              <div key={index}>{element}</div>
            ))}

            {relatedBlogs.length > 0 && (
              <RelatedBlogs
                relatedBlogs={relatedBlogs.filter(blog => blog.id !== id)}
                handleBlogClick={handleBlogClick}
              />
            )}


            <div onClick={() => handleClickOpen()}>
              <button className={Styles["cta-button"]}>Know More</button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Blog;


